ul li .nav-link,
ul li .dropdown-item{
    font-size:1.6rem;
}
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing: border-box;
    font-family: "Poppins";
}
html{
    font-size:62.5%;
}
.btn_login{
         background-color: #E81010;
        border-radius: 10px;
        color: #fff;
        padding:1rem 3.5rem;
        display: inline-block;
        text-decoration: none;
        font-size:1.8rem
     
}
.btn_login:hover{
    color: #fff;
}