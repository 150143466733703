.trailer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9000;
    transition: transform 4s;
    background:rgba(0,0,0,.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .trailer video {
    position: relative;
    max-width: 900px;
    border-radius: 10px;
    max-height:90vh;
  }
  .trailer .close__video {
    position: absolute;
    top: 5%;
    right: 20px;
    filter: invert(1);
    width: 30px;
    cursor: pointer;
  }
  .trailer.watch {
    display: flex;
  }