*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing: border-box;
}
body{
    box-sizing: border-box;
}
ul,ol{
    list-style-type: none;
}
.header_registration{
width:100%;
min-height:70vh;
background:url("../../assets/bg_aboutP.jpg");
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
padding:2rem 6rem;
position:relative;
}
section.services-main {
    background: none !important;
}
.header_registration .hamburger{
    font-size: 3rem;
    color:#fff;
    display:none;

}
.header_registration nav{
   
    display:flex;
    justify-content: space-between;
    margin-left:2rem;
    align-items: center;

}

.header_registration nav ul.navigation{
    display:flex;
    list-style-type: none;
    
}
.header_registration nav ul.navigation li a{
    text-decoration: none;
    font-weight: 900;
    color:#fff;
    font-size: 1.5rem;
    line-height: 3.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: capitalize;
    font-family: "Poppins";
    cursor: pointer;
}
.header_registration nav ul.navigation li{
    position:relative;
}
.header_registration nav ul.navigation li ol.dropdown{
    width:19rem; 
    padding:1.5rem;
    list-style-type: none;
    background:#fff;
    display: flex;
    flex-direction: column;  
    position:absolute; 
    border-radius:1rem;
    transform:translateY(4rem);
    transition: all .5s;
    opacity:0;
    z-index:100;
}
.header_registration nav ul.navigation li:hover  ol.dropdown{
    transform:translateY(0px);
    opacity:1;
}
.header_registration nav ul.navigation li ol.dropdown li{
    font-size:1.5rem;
    font-weight: 600;
    padding:1rem 1.3rem;
}
.header_registration nav .contact_us button.btn{
    outline:none;
    border:none;
    padding: 1rem 5.5rem ;
    border-radius: 50px;
    letter-spacing: 0.05rem;
    color: #252525;
    background: #fff;
    font-size:1.4rem;
    font-weight:bold;
}
.header_registration h2{
     position:absolute;
     top:50%;
     left:50%;
     transform: translate(-50%, -50%);
     color:#fff;
     font-size: 3rem;
     text-transform: uppercase;
}
section.services-main{
    padding:5rem 0;
}
section.services-main ul{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    max-width:100rem;
    width:100%;
    margin:0 auto;
    margin-top:4rem;
    gap:2rem;
   
 }
 section.services-main ul li{
 background-color: #fff;
 padding:3rem;
 border-radius: 10px;
 display:flex;
 flex-direction: column;
 align-items: center;
 box-shadow: 0px 0px .2rem  rgba(0,0,0,.5);
 }
 section.services-main ul li .image_wrap{
     width:10rem;
     height:10rem;
 }
 section.services-main ul li .image_wrap img{
     width:100%;
     height:100%;
     object-fit: cover;
 }
 section.services-main ul li h2{
    text-align: center;
    margin:1.5rem 0;
}


button.btn
{
    outline:none;
    border:none;
    border-radius: 5rem;
    letter-spacing: 0.05rem;
    font-size:1.8rem;
    font-weight: 700;
    background: #007cba;
    position:relative;
    overflow: hidden;
    cursor:pointer;
    z-index:20;
    position: relative;
}
button.btn.white.special{
    padding:1rem 1.5rem;
    font-size:1.2rem;
    color:#fff;
}
@media screen and (max-width:1038px){
    .header_registration{
        padding:3rem;
        }
        .header_registration nav{
            margin-left:0px;    
        }
       
}
@media screen and (max-width:989px){
    .header_registration nav ul.navigation li a{

        padding-left: .7rem;
        padding-right: .7rem;

    }
    
}
@media screen and (max-width:936px){
    .header_registration .hamburger{
        display:block;
    }
    .header_registration nav ul.navigation{
        display:none;
        cursor:pointer;  
    }
    

}

@media screen and (max-width:440px){
    .header_registration nav .contact_us button.btn{
        padding: .5rem 1.5rem ;
        border-radius: 1rem;
        font-size:1.1rem;
    }
    .header_registration h2{
        font-size: 2rem;
   }
}


@media screen and (max-width:628px){
    button.btn{
        padding: 1rem 2.5rem ;
        border-radius: 5rem;
        letter-spacing: 0.05rem;
        font-size:1.8rem;
        font-weight: 700;
        background:#007cba;
    }
}
