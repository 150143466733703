button.custom{
    width:65px;
    height:60px;
    background:red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:none;
    cursor: pointer;
    display:none;
    border-radius: 5px;
}
button.custom span{
    display:inline-block;
    width:50%;
    height:3px;
    background:#fff;
    pointer-events: none;
   
}
button.custom span:not(:last-child){
    margin-bottom:6px;
}
*,*::after,*::before{
    padding:0;
    margin:0;
    box-sizing:border-box
}
@media screen and (max-width:991px){
    button.custom{
        display: flex
    }
}