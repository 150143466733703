/* -------------BEGINNING OF FOOTER------------- */
footer{
    width:100%;
    background: linear-gradient(to right, rgba(185, 173, 173,.1), rgba(128, 128, 128,.4)), url(../../assets/bg_service.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content: space-between;
    padding:9rem 7rem;
}
footer .footer_left{
    display: flex;
}
footer .footer_left .individual_item{
    margin-right:4rem;
}
footer .footer_left .individual_item h2,
footer .footer_right h2{
    font-size:2.8rem;
    font-weight: 900;
    margin-bottom:1.5rem;
    color:#fff;
}
footer .footer_left .individual_item ul li{
    font-size:1.6rem;
    font-style: italic;
    font-weight: 400;
    margin-bottom:1rem;
    color:rgb(223, 203, 203)
}
footer .footer_middle{
    width:1px;
    height:auto;
    background-color: #fff;
}
button.btn.white.special{
    padding:1rem 1.5rem;
    font-size:1.2rem;
}
footer .footer_right p,
footer .footer_right h3{
    color:#fff;
}
footer .footer_right p{
    font-size:1.6rem;
}
footer .footer_right .social_media_wrapper{
    display: flex;
    height:auto;
    align-items: center;
}
footer .footer_right .social_media_wrapper h3{
  margin-right:2rem;
  font-size: 2.2rem;

}
footer .footer_right .social_media_wrapper ul{
    display:flex;
    padding:0;
    margin:0;
}
footer .footer_right .social_media_wrapper ul li:not(:last-of-type){
    margin-right:1rem;
}
footer .footer_right .social_media_wrapper ul li a > *{
    font-size: 2.5rem;
}
footer .footer_right .social_media_wrapper ul li:hover a > *{
    animation-name:animate_social;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes animate_social{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.5)
    }
}
footer .footer_right .social_media_wrapper ul li .facebook{
    color:var(--color-facebook);
}
footer .footer_right .social_media_wrapper ul li .twitter{
    color:var(--color-twitter);
}
footer .footer_right .social_media_wrapper ul li .instagram{
    color:var(--color-twitter);
}
footer .footer_right .social_media_wrapper ul li .youtube{
    color:var(--color-youtube);
}
footer .footer_right .social_media_wrapper ul li .telegram{
    color:var(--color-telegram);
}
footer .footer_right .social_media_wrapper ul li .linkedin{
    color:var(--color-linkedin);
}

footer .footer_right .subscribe_box{
    width:100%;
    border:.1rem solid #fff;
    display:flex;
    background-color: #fff;
    border-radius: 3rem;
    overflow:hidden;
    padding:.5rem;
    margin-top:1rem;
    margin-bottom:1rem;
}
footer .footer_right .subscribe_box input{
    flex:1;
    border:none;
    outline:none;
    padding:0 1rem;
}
/* -------------END OF FOOTER------------------- */


/* -----------Media query------------- */

@media screen and (max-width:1058px){
    footer .footer_left .individual_item h2,
footer .footer_right h2{
    font-size:2rem;
    font-weight: 500;
    margin-bottom:1.5rem;
}
}


@media screen and (max-width:892px){

    footer .footer_right{
        margin-left:2rem;
    }
    footer{
        padding:5rem 2rem;
    }
    footer .footer_left .individual_item{
        margin-right:2rem;
    }
}

@media screen and (max-width:710px){
    footer{
        justify-content: space-between;
        flex-direction: column;
        padding:5rem 7rem;
    }

    footer .footer_middle{
        width:100%;
        height:.01rem;
        margin-bottom: 2rem;
    }
    footer .footer_left{
        justify-content: space-between;
        margin-bottom:2rem;
    }
}

@media screen and (max-width:628px){
    button.btn{
        padding: 1rem 2.5rem ;
        border-radius: 5rem;
        letter-spacing: 0.05rem;
        font-size:1.8rem;
        font-weight: 700;
        background:var(--color-btn);
    }
}
@media screen and (max-width:508px){
    footer .footer_left{
        display: flex;
        flex-wrap: wrap;
    }
    footer .footer_left .individual_item{
    margin-right:4rem;
    margin-bottom:3rem;
}
footer{
    padding:5rem 2rem;
}

}

@media screen and (max-width:378px){
    footer .footer_right .social_media_wrapper ul li:not(:last-of-type){
        margin-right:.5rem;
    }
}
@media screen and (max-width:362px){
    html{
        font-size: 50%;
    }
    footer .footer_right{
        margin-left:0rem;
    }
}